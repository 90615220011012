html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
}

a {
  color: #20a8d8;
  text-decoration: none !important
}

html {
  font-size: 16px;
}

@media (max-width: 576px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  html {
    font-size: 15px;
  }
}


::-webkit-scrollbar-track {
  background-color: #78808E
}

::-webkit-scrollbar-thumb {
  background-color: rgba(54, 69, 93, 0.2) !important;
  border-radius: 50px !important;
  border: 3px solid #c0c7cd
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  scroll-behavior: smooth !important
}
